import * as React from "react";
import GetConsultationComp from "../../CommonComponents/GetConsultation/GetConsultationComp";

function GetConsultation() {

  return (
    <>
        <GetConsultationComp/>
    </>
  );
}

export default GetConsultation;
