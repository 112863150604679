import * as React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./GetConsultation.css";
import video from "../../../assets/HagnosTech.mp4";
import thumbnail from "../../../assets/Thumbnail.jpg";
import VideoPlayer from "../../VideoPlayer/VideoPlayer";
import { useLocation } from "react-router-dom";
import Modal from "../../CommonComponents/Modal/Modal";

const validationSchema = Yup.object({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  mobileNumber: Yup.string()
    .required("Mobile Number is required")
    .matches(/^\d{7,15}$/, "Mobile Number must be between 7 and 15 digits"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  message: Yup.string().required("Message is required"),
});

function GetConsultationComp() {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false); // Add loading state

  const location = useLocation();

  const handleSubmit = async (values) => {
    setLoading(true); // Start loading
    try {
      const response = await fetch("https://hagnos.tech/api/send-email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: `${values.firstName} ${values.lastName}`,
          email: values.email,
          message: values.message,
          phoneNumber: values.mobileNumber,
        }),
      });

      if (response.ok) {
        setIsModalOpen(true);
      } else {
        console.error("Error sending email");
      }
    } catch (error) {
      console.error("Error:", error);
    }
    finally {
      setLoading(false)
    }
  };

  return (
    <div className="flex flex-col">
  <div className="flex overflow-hidden flex-col pt-6 w-full max-md:max-w-full">
    <div className="container">
      {location.pathname === "/" && (
        <VideoPlayer videosrc={video} thumbnail={thumbnail} />
      )}
    </div>
    <div
      className={`flex flex-col items-center ${
        location.pathname === "/" ? "pt-48 lg:pt-64" : "pt-20"
      } pb-28 w-full bg-zinc-100 max-md:py-34 max-md:max-w-full`}
    >
      <div className="container">
        <div className="flex flex-col lg:flex-row">
          <div className="flex flex-col zero:px-0 lg:px-4">
            <div className="self-start px-5 py-3 typography-h5-sky font-semibold text-center text-[#40AADE] uppercase bg-white bg-opacity-90 rounded-[44px]">
              Custom Project & Consultation
            </div>
            <div className="mt-3 typography-h2 font-bold text-zinc-800 max-md:max-w-full">
              Get consultation today!
            </div>
            <div className="mt-3 typography-p text-neutral-500 max-md:max-w-full">
              We are a global provider of innovative solutions designed to
              drive excellence across all aspects of your business. Our approach
              combines deep industry knowledge with cutting-edge technology,
              ensuring that we deliver tailored strategies that meet the unique
              needs of each client.
            </div>
          </div>
          <div className="mt-10 lg:mt-0 zero:px-3 lg:px-4">
            <Formik
              initialValues={{
                firstName: "",
                lastName: "",
                mobileNumber: "",
                email: "",
                message: "",
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ handleChange, values }) => (
                <Form className="row typography-p2 leading-9 text-zinc-800 gap-4">
                  <div className="px-11 py-3 rounded-md bg-white bg-opacity-90 col-5">
                    <Field
                      type="text"
                      name="firstName"
                      placeholder="First name"
                      className="w-full bg-transparent outline-none"
                      aria-label="Your First name"
                      value={values.firstName}
                      onChange={handleChange}
                    />
                    <ErrorMessage name="firstName">
                      {msg => <div className="text-red-500 text-sm">{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <div className="px-11 py-3 rounded-md bg-white bg-opacity-90 col-5">
                    <Field
                      type="text"
                      name="lastName"
                      placeholder="Last name"
                      className="w-full bg-transparent outline-none"
                      aria-label="Your Last name"
                      value={values.lastName}
                      onChange={handleChange}
                    />
                    <ErrorMessage name="lastName">
                      {msg => <div className="text-red-500 text-sm">{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <div className="px-11 py-3 rounded-md bg-white bg-opacity-90 col-5">
                    <Field
                      type="tel"
                      name="mobileNumber"
                      placeholder="Phone number"
                      className="w-full bg-transparent outline-none"
                      aria-label="Phone number"
                      value={values.mobileNumber}
                      onChange={handleChange}
                    />
                    <ErrorMessage name="mobileNumber">
                      {msg => <div className="text-red-500 text-sm">{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <div className="px-11 py-3 rounded-md bg-white bg-opacity-90 col-5">
                    <Field
                      type="email"
                      name="email"
                      placeholder="Email"
                      className="w-full bg-transparent outline-none"
                      aria-label="Email"
                      value={values.email}
                      onChange={handleChange}
                    />
                    <ErrorMessage name="email">
                      {msg => <div className="text-red-500 text-sm">{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <div className="px-11 pt-6 h-[200px] mt-6 max-w-full rounded-md bg-white bg-opacity-90 col-11">
                    <Field
                      as="textarea"
                      name="message"
                      placeholder="Your Message"
                      className="w-full h-full bg-transparent outline-none resize-none"
                      aria-label="Your Message"
                      value={values.message}
                      onChange={handleChange}
                    />
                    <ErrorMessage name="message">
                      {msg => <div className="text-red-500 text-sm">{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <button
                    type="submit"
                    className="col-5 self-start py-3 mt-14 typography-p2 font-semibold leading-snug text-center text-white uppercase bg-[#40AADE] rounded-[40px]"
                    disabled={loading}
                  >
                    {loading ? (
                      <svg
                        className="animate-spin h-5 w-5 text-white mx-auto"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                        ></path>
                      </svg>
                    ) : (
                      "Get Consult"
                    )}
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
</div>

  );
}

export default GetConsultationComp;



