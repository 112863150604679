import React, { useEffect } from "react";
import "./Modal.css"; // Add your own styles or adjust according to your design

const Modal = ({ isOpen, onClose }) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";

      const timer = setTimeout(() => {
        onClose();
      }, 2500);

      return () => {
        clearTimeout(timer); 
        document.body.style.overflow = ""; 
      };
    } else {
      document.body.style.overflow = "";
    }
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div className={`modal-overlay ${isOpen ? "fade-in" : "fade-out"}`} style={{ zIndex: 40 }}>
      <div className="modal-content bg-white">
        <h2 className="typography-h2">Thank you for getting in touch!</h2>
        <p className="typography-h5-sky">We will connect with you shortly.</p>
      </div>
    </div>
  );
};

export default Modal;
