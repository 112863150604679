import React from "react";
import contentManagementIcon1 from "../../../assets/contentM1.png";
// import "../Consultation.css";
import GetConsultation from "../../Home/GetConsultation/GetConsultation";
import Carousel from "../../../components/CommonComponents/Carousel/Carousel";
import Head from "../../../assets/Consulting/Head.svg";
import ITServices1 from "../../../assets/ITServices/ITServices1.svg";
import ITServices2 from "../../../assets/ITServices/ITServices2.svg";
import ITServices3 from "../../../assets/ITServices/ITServices3.svg";
import ITServices4 from "../../../assets/ITServices/ITServices4.svg";
import Footer from "../../Footer.jsx/Footer";

function ITServices() {
    const sliderData = [
        {
            backgroundImage: Head,
            title: "Information Technology Services",
            description:
                "Innovative Solutions for IT Infrastructure Management and Optimization to Drive Business Growth and Efficiency.",
        },
    ];

    return (
        <>
            <Carousel sliderData={sliderData} />


            <div className="container">
                <section className="flex flex-col text-center">
                    <section className="flex flex-col mx-auto">
                        <h1 className="typography-h2-sky font-bold leading-none text-zinc-800 mt-5 mb-3">
                            Information Technology Services
                        </h1>
                    </section>

                    <p className={`typography-p2 leading-10 text-neutral-500`}>
                        Information Technology Services play a critical role in driving innovation, optimizing business operations, and ensuring seamless connectivity across organizations. From cloud computing and cybersecurity to application development and IT infrastructure management, these services empower businesses to stay competitive in a digital-first world. With expert IT consulting, companies can align technology with strategic goals, improving efficiency and scalability. Advanced data analytics, disaster recovery solutions, and managed IT services further enhance operational resilience, safeguarding critical assets and enabling informed decision-making, while fostering long-term growth and innovation.
                    </p>
                </section>
            </div>

            <section className="container py-20 pb-0">
                <div className="pb-14">
                    <div className="flex gap-5 flex-col-reverse md:flex-row">
                        <div className="flex flex-col w-full md:w-[45%]">
                            <div className="flex flex-col grow items-center md:items-end px-0 md:px-20 pt-0 md:pt-56 mt-0 md:mt-24">
                                <img
                                    loading="lazy"
                                    src={ITServices3}
                                    className="object-cover h-[550px] w-full"
                                    alt="Knowledge Management illustration"
                                    style={{ borderRadius: "44px" }}
                                />
                            </div>
                        </div>
                        <div className="flex flex-col w-full md:w-[55%]">
                            <div className="flex flex-col w-full font-bold">
                                <h4 className="typography-h4 leading-none text-sky-400 uppercase mt-10 mb-0">
                                    APPLICATION PORTFOLIO ASSESSMENT
                                </h4>
                                <p className="typography-p mt-4 font-bold">
                                    Application Portfolio Assessment (APA) involves systematically evaluating an organization’s software applications to determine their value, efficiency, and alignment with business objectives. This assessment identifies underperforming, redundant, or outdated applications, providing insights that guide strategic decisions on application optimization, consolidation, or retirement. By conducting an APA, organizations can better manage their IT assets, reduce costs, and ensure their application portfolio supports business goals. Here’s why it’s important
                                </p>

                                <ul className="mt-2 typography-p font-medium leading-10 text-zinc-600 list-disc pl-5">
                                    <li>
                                        <b>Identifies Cost Savings: </b> APA can uncover redundant or underutilized applications,
                                        potentially reducing software and maintenance costs by up to 25% through consolidation
                                        or elimination.
                                    </li>
                                    <li>
                                        <b>Improves Application Efficiency: </b> By assessing each application's performance and
                                        relevance, organizations can enhance overall application efficiency by 20%, ensuring critical
                                        operations are supported by the most effective tools.
                                    </li>
                                    <li>
                                        <b>Enhances Strategic Alignment: </b> APA provides a clear view of how each application
                                        contributes to business objectives, leading to a 30% improvement in aligning IT resources
                                        with strategic goals.
                                    </li>
                                </ul>

                            </div>
                        </div>
                    </div>

                    <ul className="mt-3 typography-p font-medium leading-10 text-zinc-600 list-disc pl-5">

                        <li>
                            <b>Supports Risk Management: </b> Identifying outdated or unsupported applications during
                            the assessment can reduce the risk of security vulnerabilities by 40%, ensuring compliance
                            and protecting sensitive data.
                        </li>
                        <li>
                            <b>Facilitates Informed Decision-Making: </b> APA offers valuable insights into the application
                            landscape, enabling more informed decisions regarding upgrades, replacements, or investments,
                            which can improve IT planning accuracy by 35%.
                        </li>
                    </ul>

                    <p className="mt-2 typography-p font-medium leading-10 text-zinc-600">
                        HagnosTech delivers effective Application Portfolio Assessment by thoroughly analyzing your existing application environment to identify areas for improvement and optimization. Our experts evaluate each application’s value, performance, and alignment with your business strategy, providing actionable recommendations that drive cost savings and operational efficiency. We also help you develop a roadmap for future application investments and consolidations, ensuring your portfolio remains aligned with your evolving business needs. By focusing on measurable outcomes such as cost reduction, efficiency gains, and risk mitigation, HagnosTech ensures that your Application Portfolio Assessment contributes to sustainable business success.
                    </p>
                </div>
            </section>

            <section className="container py-20 pb-0">
                <div className="pb-6">
                    <div className="flex gap-5 flex-col-reverse md:flex-row">
                        <div className="flex flex-col w-full md:w-[45%]">
                            <div className="flex flex-col w-full font-bold">
                                <h4 className="typography-h4 leading-none text-sky-400 uppercase mt-10 mb-0">
                                    IT ASSET MANAGEMENT ASSESSMENT
                                </h4>
                                <p className="typography-p mt-4 font-bold">
                                    IT Asset Management Assessment involves evaluating an organization’s current IT asset management (ITAM) practices to identify strengths, weaknesses, and opportunities for improvement. This assessment provides a comprehensive review of how IT assets, including hardware, software, and network components, are tracked, managed, and utilized. By conducting an ITAM assessment, organizations can enhance asset visibility, optimize usage, and ensure alignment with business objectives, ultimately leading to better decision-making and cost management. Here’s why it’s important:
                                </p>
                                <ul className="mt-2 typography-p font-medium leading-10 text-zinc-600 list-disc pl-5">
                                    <li>
                                        <b>Identifies Cost-Saving Opportunities: </b> An ITAM assessment can uncover inefficiencies
                                        and underutilized assets, potentially reducing IT expenses by up to 20% through better resource
                                        allocation and the elimination of unnecessary assets.
                                    </li>
                                    <li>
                                        <b>Improves Asset Utilization: </b> The assessment helps organizations optimize the use of
                                        existing assets, improving utilization rates by 25% and ensuring that resources are fully leveraged.
                                    </li>
                                </ul>

                            </div>
                        </div>
                        <div className="flex flex-col w-full md:w-[55%]">
                            <div className="flex flex-col grow items-center md:items-end px-0 md:px-20 pt-0 md:pt-56 mt-0 md:mt-24">
                                <img
                                    loading="lazy"
                                    src={ITServices1}
                                    className="object-cover h-[550px] w-full"
                                    alt="Annual Report illustration"
                                    style={{ borderRadius: "44px" }}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <ul className=" typography-p font-medium leading-10 text-zinc-600 list-disc pl-5">

                    <li>
                        <b>Enhances Compliance: </b> By evaluating current ITAM practices, the assessment can reduce
                        non-compliance risks by 30%, ensuring that software licensing and asset management policies
                        are properly enforced.
                    </li>
                    <li>
                        <b>Supports Risk Management: </b> An ITAM assessment can identify outdated or unsupported assets,
                        reducing the risk of security vulnerabilities by 40% and ensuring that the IT environment
                        is secure and up-to-date.
                    </li>
                    <li>
                        <b>Facilitates Strategic Planning: </b> The assessment provides valuable insights into asset
                        performance and management practices, improving the accuracy of future IT planning and budgeting by 35%.
                    </li>
                </ul>

                <p className="mt-2 typography-p font-medium leading-10 text-zinc-600">
                    HagnosTech delivers effective IT Asset Management Assessment by conducting a detailed review of your current ITAM practices and identifying areas for improvement. Our experts analyze your asset inventory, usage patterns, and compliance status, providing actionable recommendations that drive cost savings and optimize asset utilization. We also help you develop a roadmap for enhancing your ITAM practices, ensuring alignment with business goals and regulatory requirements. By focusing on measurable outcomes such as cost reduction, improved utilization, and risk mitigation, HagnosTech ensures that your ITAM assessment contributes to sustained business success.
                </p>
            </section>




            {/* <section className="container py-20 pb-0">
                <div className="pb-14">
                    <div className="flex gap-5 flex-col-reverse md:flex-row">
                        <div className="flex flex-col w-full md:w-[45%]">
                            <div className="flex flex-col grow items-center md:items-end px-0 md:px-20 pt-0 md:pt-56 mt-0 md:mt-24">
                                <img
                                    loading="lazy"
                                    src={ITServices4}
                                    className="object-cover h-[550px] w-full"
                                    alt="Pyramid Optimization illustration"
                                    style={{ borderRadius: "44px" }}
                                />
                            </div>
                        </div>

                        <div className="flex flex-col w-full md:w-[55%]">
                            <div className="flex flex-col w-full font-bold">
                                <h4 className="typography-h4 leading-none text-sky-400 uppercase mt-10 mb-0">
                                    PROBLEM MANAGEMENT
                                </h4>
                                <p className="typography-p mt-4 font-bold">
                                    Problem Management involves identifying, analyzing, and resolving the underlying causes of recurring IT incidents to prevent future disruptions. This process aims to minimize the impact of incidents on business operations by addressing root causes rather than just symptoms. By implementing effective problem management practices, organizations can improve service reliability, reduce downtime, and enhance overall IT efficiency. Here’s why it’s important:
                                </p>
                                <ul className="mt-2 typography-p font-medium leading-10 text-zinc-600 list-disc pl-5">
                                    <li>
                                        <b>Reduces Incident Recurrence:</b> Effective problem management can decrease the recurrence
                                        of IT incidents by up to 40%, ensuring a more stable and reliable IT environment.
                                    </li>
                                    <li>
                                        <b>Improves Service Availability:</b> By addressing root causes, problem management can increase
                                        overall service availability by 30%, leading to fewer disruptions and better business continuity.
                                    </li>
                                    <li>
                                        <b>Optimizes IT Resources:</b> Problem management reduces the time and resources spent on repeatedly
                                        fixing the same issues, improving IT team productivity by 25%.
                                    </li>
                                </ul>

                            </div>
                        </div>
                    </div>

                    <ul className="mt-5 typography-p font-medium leading-10 text-zinc-600 list-disc pl-5">

                        <li>
                            <b>Enhances User Satisfaction:</b> Proactively resolving problems before they impact users can
                            improve user satisfaction by 20%, leading to a more positive perception of IT services.
                        </li>
                        <li>
                            <b>Supports Continuous Improvement:</b> Problem management provides valuable insights into system
                            weaknesses, driving continuous improvement efforts and enhancing overall IT service management by 35%.
                        </li>
                    </ul>

                    <p className="mt-2 typography-p font-medium leading-10 text-zinc-600">
                        In summary, Pyramid Optimization is vital for improving efficiency,
                        productivity, and clarity, ensuring strategic alignment, effective
                        resource allocation, and strong leadership, all of which contribute
                        to organizational success. HagnosTech excels in providing Pyramid
                        Optimization services designed to elevate your organization’s
                        performance and structure. We strategically align your processes and
                        resources within a well-defined hierarchy, ensuring that every level
                        of your organization operates with maximum efficiency. Our approach
                        streamlines workflows and eliminates redundancies, leading to
                        increased productivity and faster decision-making. By clarifying
                        roles, responsibilities, and reporting lines, we enhance
                        organizational clarity and improve communication. We ensure that
                        your strategic goals are effectively cascaded through all levels,
                        aligning actions and decisions with your overarching objectives.
                        Additionally, our services optimize resource allocation, applying
                        the right resources to the most impactful areas. We facilitate
                        smoother change management by providing a clear framework for
                        implementing transitions and aligning teams.
                    </p>
                </div>
            </section> */}

            <section className="container py-20 pb-0">
                <div className="pb-8">
                    <div className="flex gap-5 flex-col-reverse md:flex-row">
                        <div className="flex flex-col w-full md:w-[45%]">
                            <div className="flex flex-col w-full font-bold">
                                <h4 className="typography-h4 leading-none text-sky-400 uppercase mt-10 mb-0">
                                    CLOUD ASSESSMENT
                                </h4>
                                <p className="typography-p mt-4 font-bold">
                                    Cloud Assessment involves evaluating an organization's current IT infrastructure, applications, and business needs to determine the best approach for cloud adoption or optimization. This process identifies the most suitable cloud services, migration strategies, and potential benefits such as cost savings, scalability, and enhanced performance. By conducting a cloud assessment, organizations can make informed decisions that align with their business goals and ensure a successful transition to the cloud. Here’s why it’s important:
                                </p>
                                <ul className="mt-2 typography-p font-medium leading-10 text-zinc-600 list-disc pl-5">
                                    <li>
                                        <b>Optimizes Cloud Costs:</b> A thorough cloud assessment can identify cost-saving opportunities,
                                        potentially reducing cloud expenditure by up to 25% through better resource allocation
                                        and optimized service selection.
                                    </li>
                                    <li>
                                        <b>Improves Scalability:</b> The assessment ensures that the chosen cloud solutions can scale
                                        effectively with business growth, enhancing scalability by 30% and supporting future expansion.
                                    </li>
                                </ul>

                            </div>
                        </div>
                        <div className="flex flex-col w-full md:w-[55%]">
                            <div className="flex flex-col grow items-center md:items-end px-0 md:px-20 pt-0 md:pt-56 mt-0 md:mt-24">
                                <img
                                    loading="lazy"
                                    src={ITServices2}
                                    className="object-cover h-[550px] w-full"
                                    alt="Career Architecture illustration"
                                    style={{ borderRadius: "44px" }}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <ul className="typography-p font-medium leading-10 text-zinc-600 list-disc pl-5">

                    <li>
                        <b>Enhances Performance:</b> By selecting the right cloud services and configurations, the assessment
                        can improve application performance by 20%, ensuring faster and more reliable service delivery.
                    </li>
                    <li>
                        <b>Reduces Migration Risks:</b> A cloud assessment helps identify potential risks and challenges
                        before migration, reducing the likelihood of issues by 35% and ensuring a smoother transition.
                    </li>
                    <li>
                        <b>Supports Strategic Alignment:</b> The assessment aligns cloud adoption with business objectives,
                        improving the alignment of IT resources with strategic goals by 40%, leading to better overall outcomes.
                    </li>
                </ul>

                <p className="mt-2 typography-p font-medium leading-10 text-zinc-600">
                    HagnosTech provides effective Cloud Assessment by thoroughly evaluating your existing IT environment and business needs to recommend the best cloud strategy. Our experts assess your infrastructure, applications, and data to identify the most suitable cloud solutions, ensuring cost efficiency, scalability, and performance. We also help you develop a detailed migration plan that minimizes risks and aligns with your strategic objectives. By focusing on measurable improvements in cost optimization, scalability, and performance, HagnosTech ensures that your cloud assessment drives long-term business success.
                </p>
            </section>


            <GetConsultation />
            <Footer />
        </>
    );
}

export default ITServices;
