import React, { useState } from 'react';
import "./VideoPlayer.css"

function VideoPlayer({ videosrc, thumbnail }) {
  return (
    <div className="video-wrapper position-relative top-10 sm:top-16 lg:top-36">
      <video width="1400" controls poster={thumbnail} >
        <source src={videosrc} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
}

export default VideoPlayer;
