import React from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/HagnosTech .jpg";
import Facebook from "../../assets/FooterLogos/Facebook.svg";
import Instagram from "../../assets/FooterLogos/Instagram.svg";
import LinkedIn from "../../assets/FooterLogos/LinkedIn.svg";
import Twitter from "../../assets/FooterLogos/Twitter.svg";
import Youtube from "../../assets/FooterLogos/Youtube.svg";

const Footer = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col pt-12 w-full bg-zinc-100 max-md:pt-24 max-md:max-w-full">
      <div className="container">
        <div className="flex flex-wrap gap-5 justify-between self-center w-full max-w-[1574px] max-md:max-w-full">
          <div className="flex flex-col self-start">
            <div className="flex flex-col typography-p2 leading-snug text-zinc-600">
              <img
                loading="lazy"
                src={Logo}
                className="object-contain max-w-full rounded-2xl aspect-[2.79] w-[230px]"
              />
              <div>Get Trusted Support From Anywhere</div>
            </div>
            <div className="flex gap-4 items-start self-start mt-4">
              {/* <img
                loading="lazy"
                src={Facebook}
                className="object-contain shrink-0 w-10 aspect-square"
              />
              <img
                loading="lazy"
                src={Twitter}
                className="object-contain shrink-0 w-10 aspect-square"
              />
              <img
                loading="lazy"
                src={Instagram}
                className="object-contain shrink-0 w-10 aspect-square"
              /> */}
              <a
                href="https://www.linkedin.com/company/hagnostech/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  loading="lazy"
                  src={LinkedIn}
                  className="object-contain shrink-0 w-10 aspect-square"
                  alt="LinkedIn"
                />
              </a>

              {/* <img
                loading="lazy"
                src={Youtube}
                className="object-contain shrink-0 w-10 aspect-square"
              /> */}
            </div>
          </div>

          <div className="flex flex-col leading-snug whitespace-nowrap text-zinc-600">
            <div className="typography-h4 font-semibold">Navigation</div>
            <div className="flex flex-col self-start mt-4 typography-p2">
              <div
                onClick={() => {
                  window.scrollTo(0, 0); // Scroll to the top
                  navigate("/");
                }}
                className="cursor-pointer hover:text-[#40AADE] transition-colors duration-300 ease-in-out"
              >
                Home
              </div>
              <div
                onClick={() => {
                  window.scrollTo(0, 0); // Scroll to the top
                  navigate("/about-us");
                }}
                className="mt-2 cursor-pointer hover:text-[#40AADE] transition-colors duration-300 ease-in-out"
              >
                About Us
              </div>
              {/* <div onClick={() => {navigate("/digital-services/content-management")}} className="mt-2 cursor-pointer hover:text-[#40AADE] transition-colors duration-300 ease-in-out">Services</div> */}
              <div
                onClick={() => {
                  window.scrollTo(0, 0); // Scroll to the top
                  navigate("/contact-us");
                }}
                className="mt-2 cursor-pointer hover:text-[#40AADE] transition-colors duration-300 ease-in-out"
              >
                Contact Us
              </div>
            </div>
          </div>

          {/* <div className="flex flex-col leading-snug whitespace-nowrap text-zinc-600">
            <div className="typography-h4 font-semibold">Social</div>
            <div className="flex flex-col mt-4 typography-p2"> */}
          {/* <div onClick={() => {navigate("/about-us")}} className="cursor-pointer hover:text-[#40AADE] transition-colors duration-300 ease-in-out">Twitter</div>
              <div onClick={() => {navigate("/about-us")}} className="mt-2 cursor-pointer hover:text-[#40AADE] transition-colors duration-300 ease-in-out" >Facebook</div>
              <div onClick={() => {navigate("/about-us")}} className="mt-2 cursor-pointer hover:text-[#40AADE] transition-colors duration-300 ease-in-out" >Instagram</div> */}
          {/* <div className="">
                <a
                  href="https://www.linkedin.com/company/hagnos-tech"
                  target="_blank"
                  className="text-decoration-none mt-2 cursor-pointer text-[#52525B] hover:text-[#40AADE] transition-colors duration-300 ease-in-out "
                >
                  LinkedIn
                </a>
              </div> */}
          {/* <div onClick={() => {navigate("/about-us")}} className="mt-2 cursor-pointer hover:text-[#40AADE] transition-colors duration-300 ease-in-out" >Youtube</div> */}
          {/* </div>
          </div> */}

          {/* <div className="flex flex-col self-start leading-snug text-zinc-600">
            <div className="typography-h4 font-semibold">Work Process</div>
            <div className="flex flex-col mt-4 typography-p2">
              <div>Choose The Service You Need</div>
              <div className="mt-2">See list of Professional Assistants</div>
              <div className="mt-2">Choose Your Package</div>
            </div>
          </div> */}
        </div>
        <div className="mt-8 w-full min-h-[1px] max-md:mt-10 max-md:max-w-full" />
        <div className="flex overflow-hidden flex-col w-full text-lg leading-none text-center bg-zinc-100 text-slate-400 max-md:max-w-full">
          <div className="px-16 py-12 w-full bg-zinc-100 max-md:px-5 max-md:max-w-full">
            Copyright © 2024 | All Rights Reserved{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
