import React, { useState } from "react";
import "./ConsultingInfo.css";


function ImageSlider({sliderData, isButtons}) {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? sliderData.length - 1 : prevIndex - 1
    );
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === sliderData.length - 1 ? 0 : prevIndex + 1
    );
  };

  const { backgroundImage, title, description } = sliderData[currentIndex];

  return (
    <div className="image-slider-container container pt-10 pb-20 mt-12">
      <div className="relative flex flex-col grow justify-center items-start min-h-[500px] max-md:max-w-full">
        <div className="image-adjust">
          <img
            loading="lazy"
            src={backgroundImage}
            alt="Consulting firm background"
            className="image-slider-img"
          />
        </div>

        <div className="consulting-info absolute top-0 right-0 w-[48%] z-20">
          <div className="glass-effect consulting-info-container">
            <div className="navigation-buttons-pot">
              <svg
                width="80"
                height="80"
                viewBox="0 0 80 80"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={handlePrevClick}
                style={{ cursor: "pointer" }}
              >
                <rect
                  width="80"
                  height="80"
                  transform="translate(80 80) rotate(-180)"
                  fill="#EFEDEA"
                />
                <path
                  d="M51.2497 40.625C51.2497 40.9565 51.118 41.2745 50.8836 41.5089C50.6491 41.7433 50.3312 41.875 49.9997 41.875L33.0122 41.875L34.6372 43.4875C34.8726 43.7229 35.0048 44.0421 35.0048 44.375C35.0048 44.7079 34.8726 45.0271 34.6372 45.2625C34.4018 45.4979 34.0826 45.6301 33.7497 45.6301C33.4168 45.6301 33.0976 45.4979 32.8622 45.2625L29.1122 41.5125C28.9387 41.3367 28.8212 41.1135 28.7745 40.871C28.7278 40.6285 28.754 40.3777 28.8497 40.15C28.9435 39.9217 29.1027 39.7263 29.3074 39.5884C29.512 39.4505 29.7529 39.3762 29.9997 39.375L49.9997 39.375C50.3312 39.375 50.6491 39.5067 50.8836 39.7411C51.118 39.9756 51.2497 40.2935 51.2497 40.625Z"
                  fill="#909090"
                />
                <path
                  d="M35.0002 36.875C35.0012 37.0395 34.9697 37.2026 34.9074 37.3549C34.8452 37.5072 34.7536 37.6457 34.6377 37.7625L30.8877 41.5125C30.6524 41.7479 30.3331 41.8801 30.0002 41.8801C29.8354 41.8801 29.6722 41.8477 29.5199 41.7846C29.3676 41.7215 29.2293 41.6291 29.1127 41.5125C28.9962 41.396 28.9037 41.2576 28.8407 41.1053C28.7776 40.953 28.7451 40.7898 28.7451 40.625C28.7451 40.2921 28.8774 39.9729 29.1127 39.7375L32.8627 35.9875C32.9789 35.8704 33.1172 35.7774 33.2695 35.7139C33.4218 35.6504 33.5852 35.6178 33.7502 35.6178C33.9152 35.6178 34.0786 35.6504 34.231 35.7139C34.3833 35.7774 34.5215 35.8704 34.6377 35.9875C34.7536 36.1043 34.8452 36.2428 34.9074 36.3951C34.9697 36.5474 35.0012 36.7105 35.0002 36.875Z"
                  fill="#909090"
                />
              </svg>
              <svg
                width="80"
                height="80"
                viewBox="0 0 80 80"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={handleNextClick}
                style={{ cursor: "pointer" }}
              >
                <rect width="80" height="80" fill="#40AADE" />
                <path
                  d="M28.7503 39.375C28.7503 39.0435 28.882 38.7255 29.1164 38.4911C29.3509 38.2567 29.6688 38.125 30.0003 38.125L46.9878 38.125L45.3628 36.5125C45.1274 36.2771 44.9952 35.9579 44.9952 35.625C44.9952 35.2921 45.1274 34.9729 45.3628 34.7375C45.5982 34.5021 45.9174 34.3699 46.2503 34.3699C46.5832 34.3699 46.9024 34.5021 47.1378 34.7375L50.8878 38.4875C51.0613 38.6633 51.1788 38.8865 51.2255 39.129C51.2722 39.3715 51.246 39.6223 51.1503 39.85C51.0565 40.0783 50.8973 40.2737 50.6926 40.4116C50.488 40.5495 50.2471 40.6238 50.0003 40.625L30.0003 40.625C29.6688 40.625 29.3509 40.4933 29.1164 40.2589C28.882 40.0245 28.7503 39.7065 28.7503 39.375Z"
                  fill="#EFEDEA"
                />
                <path
                  d="M45.0002 43.125C44.9992 42.9605 45.0307 42.7974 45.0929 42.6451C45.1552 42.4928 45.2468 42.3543 45.3627 42.2375L49.1127 38.4875C49.348 38.2521 49.6673 38.1199 50.0002 38.1199C50.165 38.1199 50.3282 38.1523 50.4805 38.2154C50.6328 38.2785 50.7711 38.3709 50.8877 38.4875C51.0042 38.604 51.0967 38.7424 51.1597 38.8947C51.2228 39.047 51.2553 39.2102 51.2553 39.375C51.2553 39.7079 51.123 40.0271 50.8877 40.2625L47.1377 44.0125C47.0215 44.1296 46.8832 44.2226 46.7309 44.2861C46.5786 44.3496 46.4152 44.3822 46.2502 44.3822C46.0852 44.3822 45.9218 44.3496 45.7695 44.2861C45.6172 44.2226 45.4789 44.1296 45.3627 44.0125C45.2468 43.8957 45.1552 43.7572 45.0929 43.6049C45.0307 43.4526 44.9992 43.2895 45.0002 43.125Z"
                  fill="#EFEDEA"
                />
              </svg>
            </div>
            <h1 className="typography-h3">{title}</h1>
            <p className="typography-h5">{description}</p>
          </div>
        </div>
        <div className={`navigation-buttons relative flex gap-5 justify-between mt-[300px] max-w-full w-[531px] max-md:flex-wrap max-md:mt-10 z-0 ${isButtons ? '' : "hidden"}`}>
          <div className="flex ml-[60px]">
            <svg
              width="80"
              height="80"
              viewBox="0 0 80 80"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={handlePrevClick}
              style={{ cursor: "pointer", width: "60px" }}
            >
              <rect
                width="80"
                height="80"
                transform="translate(80 80) rotate(-180)"
                fill="#EFEDEA"
              />
              <path
                d="M51.2497 40.625C51.2497 40.9565 51.118 41.2745 50.8836 41.5089C50.6491 41.7433 50.3312 41.875 49.9997 41.875L33.0122 41.875L34.6372 43.4875C34.8726 43.7229 35.0048 44.0421 35.0048 44.375C35.0048 44.7079 34.8726 45.0271 34.6372 45.2625C34.4018 45.4979 34.0826 45.6301 33.7497 45.6301C33.4168 45.6301 33.0976 45.4979 32.8622 45.2625L29.1122 41.5125C28.9387 41.3367 28.8212 41.1135 28.7745 40.871C28.7278 40.6285 28.754 40.3777 28.8497 40.15C28.9435 39.9217 29.1027 39.7263 29.3074 39.5884C29.512 39.4505 29.7529 39.3762 29.9997 39.375L49.9997 39.375C50.3312 39.375 50.6491 39.5067 50.8836 39.7411C51.118 39.9756 51.2497 40.2935 51.2497 40.625Z"
                fill="#909090"
              />
              <path
                d="M35.0002 36.875C35.0012 37.0395 34.9697 37.2026 34.9074 37.3549C34.8452 37.5072 34.7536 37.6457 34.6377 37.7625L30.8877 41.5125C30.6524 41.7479 30.3331 41.8801 30.0002 41.8801C29.8354 41.8801 29.6722 41.8477 29.5199 41.7846C29.3676 41.7215 29.2293 41.6291 29.1127 41.5125C28.9962 41.396 28.9037 41.2576 28.8407 41.1053C28.7776 40.953 28.7451 40.7898 28.7451 40.625C28.7451 40.2921 28.8774 39.9729 29.1127 39.7375L32.8627 35.9875C32.9789 35.8704 33.1172 35.7774 33.2695 35.7139C33.4218 35.6504 33.5852 35.6178 33.7502 35.6178C33.9152 35.6178 34.0786 35.6504 34.231 35.7139C34.3833 35.7774 34.5215 35.8704 34.6377 35.9875C34.7536 36.1043 34.8452 36.2428 34.9074 36.3951C34.9697 36.5474 35.0012 36.7105 35.0002 36.875Z"
                fill="#909090"
              />
            </svg>
            <svg
              width="80"
              height="80"
              viewBox="0 0 80 80"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={handleNextClick}
              style={{ cursor: "pointer", width: "60px" }}
            >
              <rect width="80" height="80" fill="#40AADE" />
              <path
                d="M28.7503 39.375C28.7503 39.0435 28.882 38.7255 29.1164 38.4911C29.3509 38.2567 29.6688 38.125 30.0003 38.125L46.9878 38.125L45.3628 36.5125C45.1274 36.2771 44.9952 35.9579 44.9952 35.625C44.9952 35.2921 45.1274 34.9729 45.3628 34.7375C45.5982 34.5021 45.9174 34.3699 46.2503 34.3699C46.5832 34.3699 46.9024 34.5021 47.1378 34.7375L50.8878 38.4875C51.0613 38.6633 51.1788 38.8865 51.2255 39.129C51.2722 39.3715 51.246 39.6223 51.1503 39.85C51.0565 40.0783 50.8973 40.2737 50.6926 40.4116C50.488 40.5495 50.2471 40.6238 50.0003 40.625L30.0003 40.625C29.6688 40.625 29.3509 40.4933 29.1164 40.2589C28.882 40.0245 28.7503 39.7065 28.7503 39.375Z"
                fill="#EFEDEA"
              />
              <path
                d="M45.0002 43.125C44.9992 42.9605 45.0307 42.7974 45.0929 42.6451C45.1552 42.4928 45.2468 42.3543 45.3627 42.2375L49.1127 38.4875C49.348 38.2521 49.6673 38.1199 50.0002 38.1199C50.165 38.1199 50.3282 38.1523 50.4805 38.2154C50.6328 38.2785 50.7711 38.3709 50.8877 38.4875C51.0042 38.604 51.0967 38.7424 51.1597 38.8947C51.2228 39.047 51.2553 39.2102 51.2553 39.375C51.2553 39.7079 51.123 40.0271 50.8877 40.2625L47.1377 44.0125C47.0215 44.1296 46.8832 44.2226 46.7309 44.2861C46.5786 44.3496 46.4152 44.3822 46.2502 44.3822C46.0852 44.3822 45.9218 44.3496 45.7695 44.2861C45.6172 44.2226 45.4789 44.1296 45.3627 44.0125C45.2468 43.8957 45.1552 43.7572 45.0929 43.6049C45.0307 43.4526 44.9992 43.2895 45.0002 43.125Z"
                fill="#EFEDEA"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ImageSlider;
